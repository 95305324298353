<template>
  <div>
    <div class="content">
      <div id="select-header">Choose one or more artists</div>
      <div class="select-container">
        <Multiselect
          v-model="chosenFeatures"
          :options="options"
          :searchable="false"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
        ></Multiselect>
      </div>
      <div class="vis-container">
        <h2 class="vis-title">{{ lineChartData.title }}</h2>
        <p class="vis-text">{{ lineChartData.text }}</p>
        <LineChart :width="700" :height="500" :chosenFeatures="chosenFeatures" />
      </div>
      <div class="vis-container">
        <h2 class="vis-title">{{ correlationMatrixData.title }}</h2>
        <p class="vis-text">{{ correlationMatrixData.text }}</p>
        <CorrelationMatrix :width="700" :height="700" />
      </div>
    </div>
  </div>
</template>

      
<script>
import Multiselect from "vue-multiselect";
import LineChart from "../components/LineChart.vue";
import CorrelationMatrix from "../components/CorrelationMatrix.vue";
import SongFinder from '../components/SongFinder.vue';

export default {
  name: "Page3",
  components: {
    LineChart,
    Multiselect,
    CorrelationMatrix
  },
  data() {
    return {
      options: [],
      chosenFeatures: [],
      lineChartData: {
        title: "Evolution of features",
        text: "This graph show the evolution of features throughout the years. In order to be able to compare values easily all values are normalised such that the maximum per features is 100. In this example we see that acousticness and energy seem to have mirrored curves, while acousticness has gone down over the years, energy has gone up."
      },
      correlationMatrixData: {
        title: "Correlation Matrix",
        text: "This correlation matrix shows the correlation between the different features in the dataset. A correlation value that's positive means that on average, if one of the features increases, the other increases as well, a correlation value that's negative means that the opposite holds. The bigger the absolute value, the stronger the correlation. In order to have more features in this correlation matrix, we had to convert some non-numeric values to numbers: we converted the releasedate to a feature called \"age\" (the amount of years since the release date). Also instead of directly using the \"duration_ms\" feature, we calculated the log of the duration (because intuitively, a difference of a second on a 30-second song means more than a difference of a second on an hour long podcast). We see for example that loudness is strongly correlated with energy, while energy and acousticness are inversely correlated."

      }
    };
  },
  mounted() {
    this.options = Object.keys(this.$dataGetter.featureColours);
    this.chosenFeatures = [this.options[0], this.options[3]];
  },
};
</script>
