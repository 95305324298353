<template>
  <div id="correlation-container" />
</template>

<script>
import * as d3 from "d3";

export default {
  name: "CorrelationMatrix",
  props: {
    width: {
      type: Number,
      required: false,
      default: 900,
    },
    height: {
      type: Number,
      required: false,
      default: 900,
    },
  },
  components: {},
  data() {
    return {
      margin: {
        top: 25,
        right: 90,
        bottom: 25,
        left: 25,
      },
      data: [],
    };
  },
  methods: {
    init(correlation) {
      var rows = JSON.parse(JSON.stringify(correlation));
      var data = [];

      rows.forEach(function (d) {
        // get property name
        var x = d[""];
        delete d[""];
        for (var prop in d) {
          var y = prop,
            value = d[prop];
          data.push({
            x: x,
            y: y,
            value: +value,
          });
        }
      });

      var width = this.width - this.margin.left - this.margin.right;
      var height = this.height - this.margin.top - this.margin.bottom;
      var variableNames = Array.from(
        new Set(
          data.map(function (d) {
            return d.x;
          })
        )
      );
      data.forEach(function (d) {
        d.xpos = variableNames.indexOf(d.x);
        d.ypos = variableNames.indexOf(d.y);
      });

      var num = Math.sqrt(data.length);
      var color = d3
        .scaleLinear()
        .domain([-1, 0, 1])
        .range(["#B22222", "#fff", "#000080"]);

      var x = d3.scalePoint().range([0, width]).domain(variableNames);
      var y = d3.scalePoint().range([0, height]).domain(variableNames);

      var xSpace = x.range()[1] - x.range()[0];
      var ySpace = y.range()[1] - y.range()[0];

      var svg = d3
        .select("#correlation-container")
        .append("svg")
        .attr("width", width + this.margin.left + this.margin.right)
        .attr("height", height + this.margin.top + this.margin.bottom)
        .append("g")
        .attr(
          "transform",
          "translate(" + this.margin.left + "," + this.margin.top + ")"
        );

      var div = d3
        .select("#correlation-container")
        .append("div")
        .attr("class", "tooltip")
        .style("visibility", "hidden");

      var cor = svg
        .selectAll(".cor")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "cor")
        .attr("transform", function (d) {
          return "translate(" + x(d.x) + "," + y(d.y) + ")";
        })
        .on("mouseover", function (ev, i) {
          if (i.xpos == i.ypos) {
            return;
          }
          div.transition().duration(200).style("visibility", "visible");
          div
            .html(i.x + " / " + i.y + "<br>" + i.value.toFixed(2))
            .style("left", ev.clientX + "px")
            .style("top", ev.pageY - 28 + "px");

          svg
            .selectAll(".cor")
            .filter(
              (c) =>
                (c.xpos == i.xpos && c.ypos == i.ypos) ||
                (c.ypos == i.xpos && c.xpos == i.ypos)
            )
            .classed("selected", true);
        })
        .on("mouseout", function (ev, i) {
          if (i.xpos == i.ypos) {
            return;
          }
          div.transition().duration(500).style("visibility", "hidden");
          svg
            .selectAll(".cor")
            .filter(
              (c) =>
                (c.xpos == i.xpos && c.ypos == i.ypos) ||
                (c.ypos == i.xpos && c.xpos == i.ypos)
            )
            .classed("selected", false);
        });

      cor
        .filter(function (d) {
          for (var i = d.ypos + 1; i < num; i++) {
            if (i === d.xpos) return false;
          }
          return true;
        })
        .append("text")
        .attr("y", 5)
        .attr("x", -20)
        .attr("transform", function (d) {
          if (d.x === d.y) {
            return "translate(-5, 0)";
          } else {
            return "";
          }
        })
        .text(function (d) {
          if (d.x === d.y) {
            return d.x;
          } else {
            return d.value.toFixed(2);
          }
        })
        .style("fill", function (d) {
          if (d.value === 1) {
            return "#000";
          } else {
            return color(d.value);
          }
        })
        .style("font-size", function (d) {
          if (d.x === d.y) {
            return "x-small";
          } else {
            return "normal";
          }
        })
        .style("text-align", "center");

      cor
        .filter(function (d) {
          for (var i = d.ypos + 1; i < num; i++) {
            if (i === d.xpos) return true;
          }
          return false;
        })
        .append("circle")
        .attr("r", function (d) {
          return (width / (num * 2)) * (Math.abs(d.value) + 0.1);
        })
        .style("fill", function (d) {
          if (d.value === 1) {
            return "#000";
          } else {
            return color(d.value);
          }
        });

      var sideScale = d3
        .scaleLinear()
        .range([-this.margin.top + 10, height + this.margin.bottom - 10])
        .domain([1, -1]);

      var sideScaleTicks = d3.axisRight().scale(sideScale).tickPadding(7);

      var actualSideScale = svg
        .append("g")
        .attr("class", "y axis")
        .call(sideScaleTicks)
        .attr(
          "transform",
          "translate(" + (width + this.margin.right / 2) + " ,0)"
        );

      // TODO replace this with a nice gradient blur
      var gradientSteps = d3.range(-1, 1.01, 0.01);
      var h = height / gradientSteps.length + 3;
      gradientSteps.forEach(function (d) {
        actualSideScale
          .append("rect")
          .style("fill", color(d))
          .style("stroke-width", 0)
          .style("stroke", "none")
          .attr("height", h)
          .attr("width", 10)
          .attr("x", 0)
          .attr("y", sideScale(d));
      });
    },
  },
  mounted() {
    const correlation = this.$dataGetter.correlation;
    this.init(correlation);
  },
};
</script>
<style>
.selected circle {
  stroke: black;
  stroke-width: 2;
}

.selected text {
  font-size: large;
  fill: black;
}
</style>
