<template>
  <div>
    <div id="releases-graph"></div>
    <div id="tooltip"></div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "ReleasesGraph",
  props: {
    width: {
      type: Number,
      required: false,
      default: 850,
    },
    height: {
      type: Number,
      required: false,
      default: 500,
    },
    chosenArtists: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      releases: {},
      margin: {
        top: 25,
        right: 50,
        left: 50,
        bottom: 25,
      },
      svg: null,
      tooltip: null,
      minDate: 0,
      maxAmount: 10,
    };
  },
  mounted() {
    this.drawReleasesGraph();
  },
  watch: {
    chosenArtists() {
      this.drawReleasesGraph();
    },
  },
  methods: {
    processData() {
      const allData = this.$dataGetter.dataRegular;

      this.releases = {};

      allData.forEach((song) => {
        const releaseDate = song.release_date;
        if (Array.isArray(song.artists)) {
          song.artists.forEach((artist) => {
            let artistName = artist;

            if (!this.releases[artistName]) {
              this.releases[artistName] = {};
            }

            if (!this.releases[artistName][releaseDate]) {
              this.releases[artistName][releaseDate] = 0;
            }

            this.releases[artistName][releaseDate] += 1;
          });
        } else {
          let artistName = "" + song.artists;
          if (!this.releases[artistName]) {
            this.releases[artistName] = {};
          }

          if (!this.releases[artistName][releaseDate]) {
            this.releases[artistName][releaseDate] = 0;
          }

          this.releases[artistName][releaseDate] += 1;
        }
      });
    },
    drawReleasesGraph() {
      this.tooltip = d3.select("#tooltip").attr("class", "tooltip").text("");
      this.processData();
      this.clearGraph();
      this.svg = d3
        .select("#releases-graph")
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height);

      this.minDate = Math.min(
        ...this.chosenArtists.map((artist) =>
          Math.min(...Object.keys(this.releases[artist]).map(this.getDate))
        )
      );

      this.maxAmount = Math.max(
        ...this.chosenArtists.map((artist) =>
          Math.max(...Object.values(this.releases[artist]))
        )
      );

      this.chosenArtists.forEach(this.drawSingleGraph);
    },
    clearGraph() {
      d3.select("#releases-graph").selectAll("*").remove();
    },
    drawSingleGraph(artist, index) {
      const ownHeight = this.height / this.chosenArtists.length;
      const g = this.svg
        .append("g")
        .attr("transform", `translate(0, ${ownHeight * index})`);
      const data = this.releases[artist];

      const xScale = d3
        .scaleTime()
        .domain([this.minDate, new Date()])
        .rangeRound([this.margin.left, this.width - this.margin.right]);

      const yScale = d3
        .scaleLinear()
        .domain([this.maxAmount, 0])
        .rangeRound([this.margin.top, ownHeight - this.margin.bottom]);

      g.append("g")
        .attr("class", "axis")
        .attr("transform", `translate(0, ${ownHeight - this.margin.bottom})`)
        .call(d3.axisBottom(xScale).ticks(7));

      g.append("g")
        .attr("class", "axis")
        .attr("transform", `translate(${this.margin.left}, 0)`)
        .call(d3.axisLeft(yScale).ticks(4));

      g.selectAll(".line")
        .data(Object.keys(data))
        .enter()
        .append("line")
        .attr("x1", (d) => xScale(this.getDate(d)))
        .attr("y1", (d) => yScale(data[d]))
        .attr("x2", (d) => xScale(this.getDate(d)))
        .attr("y2", (d) => ownHeight - this.margin.bottom)
        .attr("stroke", "var(--color-primary)")
        .on("mouseover", () => this.tooltip.style("visibility", "visible"))
        .on("mousemove", (d, i) => {
          const songText = data[i] == 1 ? "song" : "songs";
          this.tooltip.text(`${i}: ${data[i]} ${songText}`);
          this.tooltip
            .style("top", d.pageY - 10 + "px")
            .style("left", d.pageX + 10 + "px");
        })
        .on("mouseout", () => this.tooltip.style("visibility", "hidden"));

      const center =
        (this.width - this.margin.right - this.margin.left) / 2 +
        this.margin.left;

      g.append("text")
        .attr("transform", `translate(${center}, ${this.margin.top})`)
        .attr("text-anchor", "middle")
        .text(artist);
    },
    getDate(str) {
      if (str.length == 4) {
        return new Date(parseInt(str), 0);
      }

      return Date.parse(str);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
