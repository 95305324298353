import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import DataGetter from "./js/dataGetter";
import "./js/dataManipulation";
import * as d3 from "d3";
import "./assets/global.css";

Vue.config.productionTip = false;

const getter = new DataGetter();
Vue.prototype.$dataGetter = getter;
Vue.prototype.$d3 = d3;
// getter.load();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
