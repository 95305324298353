<template>
  <div id="app">
    <Header />
    <Legend v-show="showLegend()" :legend_data="getLegendData()" />
    <router-view v-if="dataLoaded" class="view" />
    <div v-else>Data loading...</div>
  </div>
</template>

<script>
import Header from "./components/Header";
import Legend from "./components/Legend";
export default {
  components: {
    Header,
    Legend,
  },
  data() {
    return {
      dataLoaded: false,
      legend_data: {
        genres: {},
        features: {},
      },
    };
  },
  methods: {
    getLegendData() {
      return window.location.href.split("/").includes("page3")
        ? this.legend_data.features
        : this.legend_data.genres;
    },
    showLegend() {
      return (
        window.location.href.includes("page") &&
        !window.location.href.includes("1")
      );
    },
  },
  mounted() {
    this.legend_data.genres = this.$dataGetter.legendGenreColours;
    this.legend_data.features = this.$dataGetter.featureColours;
    if (!this.$dataGetter.allDataLoaded) {
      console.log("Initializing data load");
      this.$dataGetter.loadAllData().then(() => (this.dataLoaded = true));
    }
  },
};
</script>
