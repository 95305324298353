<template>
  <div>
    <table>
      <tbody>
        <tr>
          <td>Begin:</td>
          <td>
            <Multiselect
              v-model="beginYear"
              :options="yearOptions"
              :close-on-select="true"
              :clear-on-select="false"
              class="yearSelector"
            ></Multiselect>
          </td>
          <td>End:</td>
          <td>
            <Multiselect
              v-model="endYear"
              :options="yearOptions"
              :close-on-select="true"
              :clear-on-select="false"
              class="yearSelector"
            ></Multiselect>
          </td>
          <td>
            <button class="button" v-on:click="handleClick">Update</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="bump-chart" />
    <div id="tooltip" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { getColumn } from "../js/dataManipulation.js";
import * as d3 from "d3";

export default {
  name: "BumpChart",
  components: {
    Multiselect,
  },
  data() {
    return {
      minColumnWidth: 65,
      maxColumnWidth: 120,
      rowHeight: 60,
      margin: { top: 20, left: 110, right: 20, bottom: 20 },
      genres: [],
      yearOptions: [],
      songData: undefined,
      genreData: undefined,
      subgenreData: undefined,
      beginYear: 0,
      endYear: 0,
      radius: 12,
      selectedRadius: 18,
      strokeWidth: 5,
      selectedStrokeWidth: 10,
      artistGenreMapping: {},
      subgenreMapping: {},
    };
  },

  methods: {
    /*
        Set the initial values and options of the year multiselect. 
      */
    createYearSelect() {
      let data = this.$dataGetter.dataYear;
      const years = getColumn(data, "year").filter((d) => d != undefined);

      this.beginYear = 2005;
      this.endYear = 2016;
      this.yearOptions = years;
    },

    /*
        Create a mapping of subgenres to their main genre.
      */
    createSubgenreMapping() {
      for (let i = 0; i < this.subgenreData.length; i++) {
        const subgenre = this.subgenreData[i].subgenre;
        if (subgenre) {
          this.subgenreMapping[subgenre] = this.subgenreData[i].genre;
        }
      }
    },

    /*
        Create a mapping of artists to their genres.
      */
    createArtistGenreMapping() {
      let subgenreMapping = this.subgenreMapping;
      for (let i = 0; i < this.genreData.length; i++) {
        const artist = this.genreData[i].artists;
        if (artist) {
          let genreList = [];
          // find the main genre corresponding to every subgenre found in genreData
          this.genreData[i].genres.forEach(function (subgenre) {
            let genre = subgenreMapping[subgenre];
            if (!genreList.includes(genre)) {
              genreList.push(genre);
            }
          });
          this.artistGenreMapping[artist] = genreList;
        }
      }
    },

    /*
        Returns a sequence with values from 'start' to 'length'
      */
    seq(start, length) {
      return Array.apply(null, { length: length }).map((_, i) => i + start);
    },

    draw() {
      let years = Array.from(
        { length: this.endYear - this.beginYear + 1 },
        (_, k) => this.beginYear + k
      );
      let numOfYears = years.length;

      let tooltip = this.tooltip;
      let songData = this.songData;
      let artistGenreMapping = this.artistGenreMapping;
      let colourMapping = this.colourData;

      let yearGenreMapping = {};
      let genreLineMapping = {};
      let bumpMatrix = [];
      let totalGenres = 0;
      let numOfShownGenres = 0;
      let leftGenres = [];
      let rightGenres = [];

      years.forEach(function (year) {
        yearGenreMapping[year] = {};
      });

      // Create the bumpMatrix which will contain the necessary information to visualise the data.
      for (let i = 0; i < numOfYears; i++) {
        let year = years[i];
        // Get the artistdata corresponding to 'year'
        let filteredData = getColumn(
          songData.filter((el) => el.year === year),
          "artists"
        );

        filteredData.forEach(function (artistArray) {
          if (typeof artistArray !== "object") {
            artistArray = [artistArray.toString()];
          }
          artistArray.forEach(function (artist) {
            if (artistGenreMapping[artist]) {
              artistGenreMapping[artist].forEach(function (genre) {
                if (genre) {
                  genre in yearGenreMapping[year]
                    ? (yearGenreMapping[year][genre] += 1)
                    : (yearGenreMapping[year][genre] = 1);
                }
              });
            }
          });
        });
        // Sort the genres on the number of occurences this year.
        const sortable = Object.entries(yearGenreMapping[year]).sort(
          ([, a], [, b]) => b - a
        );

        numOfShownGenres = Math.max(numOfShownGenres, sortable.length);

        for (let j = 0; j < sortable.length; j++) {
          let genre = sortable[j][0];

          if (i == 0) {
            leftGenres.push(genre);
          }

          if (i == numOfYears - 1) {
            rightGenres.push(genre);
          }

          if (!(genre in genreLineMapping)) {
            genreLineMapping[genre] = totalGenres;
            bumpMatrix.push([{ rank: j, genre: genre, column: i }]);
            totalGenres += 1;
          } else {
            let index = genreLineMapping[genre];
            bumpMatrix[index].push({ rank: j, genre: genre, column: i });

            let len = bumpMatrix[index].length;
            if (bumpMatrix[index][len - 2].column == i - 1) {
              bumpMatrix[index][len - 2].nextRank = j;
            }
          }
        }
      }

      let columnWidth = Math.min(this.maxColumnWidth, Math.max(this.minColumnWidth, 5*(27 - numOfYears)));
      console.log(columnWidth);
      let width = numOfYears * columnWidth;
      let height = numOfShownGenres * this.rowHeight;

      const svg = d3
        .select("#bump-chart")
        .append("svg")
        .attr("width", width + this.margin.left + this.margin.right)
        .attr("height", height + this.margin.top + this.margin.bottom);

      const xScale = d3
        .scaleLinear()
        .domain([0, numOfYears - 1])
        .range([this.margin.left, width - this.margin.right]);

      const yScale = d3
        .scaleLinear()
        .domain([numOfShownGenres, 0])
        .range([height - this.margin.bottom, this.margin.top]);

      const xAxis = d3
        .axisBottom()
        .scale(xScale)
        .ticks(numOfYears - 1)
        .tickFormat((_, i) => years[i]);

      const yAxisLeft = d3
        .axisLeft()
        .scale(yScale)
        .ticks(numOfShownGenres)
        .tickFormat((_, i) => {
          if (i > 0) {
            return leftGenres[numOfShownGenres - i];
          }
        });

      const yAxisRight = d3
        .axisRight()
        .scale(yScale)
        .ticks(numOfShownGenres)
        .tickFormat((_, i) => {
          if (i > 0) {
            return rightGenres[numOfShownGenres - i];
          }
        });

      // draw x axis
      svg
        .append("g")
        .style("font-weight", "bold")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height - this.margin.bottom})`)
        .call(xAxis);

      // draw left y-axis
      svg
        .append("g")
        .style("font-weight", "bold")
        .attr("class", "y axis")
        .attr(
          "transform",
          `translate(${this.margin.left - this.selectedRadius}, 0)`
        )
        .call(yAxisLeft)
        .select(".domain")
        .remove();

      // draw right y-axis
      svg
        .append("g")
        .style("font-weight", "bold")
        .attr("class", "y axis")
        .attr(
          "transform",
          `translate(${width - this.margin.right + this.selectedRadius}, 0)`
        )
        .call(yAxisRight)
        .select(".domain")
        .remove();

      /*
            Help function used to calculate the coordinates of the vertical axes
          */
      const getGridCoord = (index) => {
        return [
          [xScale(index) - this.margin.left, 0],
          [xScale(index) - this.margin.left, height - this.margin.top],
        ];
      };

      // draw grid lines
      svg
        .append("g")
        .attr("transform", `translate(${this.margin.left}, 0)`)
        .selectAll("path")
        .data(this.seq(0, numOfYears))
        .join("path")
        .attr("stroke", "#ccc")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", "5,5")
        .attr("d", (d) => d3.line()(getGridCoord(d)));

      // select rows in the matrix
      const lines = svg
        .selectAll(".lines")
        .data(bumpMatrix)
        .enter()
        .append("g")
        .attr("class", "lines")
        .attr("id", (d) => "genre" + genreLineMapping[d[0].genre].toString())
        .on("mouseover", (_, d) => mouseOverMethod(d))
        .on("mousemove", function (d) {
          tooltip
            .style("top", d.pageY - 10 + "px")
            .style("left", d.pageX + 10 + "px");
        })
        .on("mouseout", (_, d) => mouseOutMethod(d));

      // draw lines corresponding to a row
      lines
        .selectAll("path")
        .data((d) => d)
        .enter()
        .append("path")
        .attr("stroke-width", this.strokeWidth)
        .attr("stroke", (d) => colourMapping[d.genre])
        .attr("d", (d) => {
          if (Number.isInteger(d.nextRank)) {
            return d3.line()([
              [xScale(d.column), yScale(d.rank)],
              [xScale(d.column + 1), yScale(d.nextRank)],
            ]);
          }
        });

      // select individual points in a row
      const circles = lines
        .selectAll("g")
        .data((d) => d)
        .enter()
        .append("g")
        .attr(
          "transform",
          (d) => `translate(${xScale(d.column)},${yScale(d.rank)})`
        );

      // draw circle corresponding to a point
      circles
        .append("circle")
        .attr("r", this.radius)
        .attr("fill", (d) => colourMapping[d.genre]);

      circles
        .append("text")
        .attr("dy", this.radius / 2)
        .attr("fill", "white")
        .attr("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("font-size", "14px")
        .text((d) => d.rank + 1);

      const mouseOverMethod = (data) => {
        let genreNumber = genreLineMapping[data[0].genre].toString();
        lines
          .filter((l) => l !== data)
          .selectAll("path")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("stroke", "#ddd")
          // necessary to avoid a bug when you move directly from one line to the next without moving outside the line
          .attr("stroke-width", this.strokeWidth);
        lines
          .filter((l) => l !== data)
          .selectAll("circle")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("fill", "#ddd")
          // necessary to avoid a bug when you move directly from one line to the next without moving outside the line
          .attr("r", this.radius);
        d3.selectAll("#genre" + genreNumber).raise();
        d3.selectAll("#genre" + genreNumber)
          .selectAll("path")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("stroke-width", this.selectedStrokeWidth)
          // necessary to avoid a bug when you move directly from one line to the next without moving outside the line
          .attr("stroke", (d) => colourMapping[d.genre]);
        d3.selectAll("#genre" + genreNumber)
          .selectAll("circle")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("r", this.selectedRadius)
          // necessary to avoid a bug when you move directly from one line to the next without moving outside the line
          .attr("fill", (d) => colourMapping[d.genre]);
        d3.selectAll("#genre" + genreNumber)
          .selectAll("text")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("dy", this.selectedRadius / 2)
          .style("font-size", "20px");
        tooltip.style("visibility", "visible");
        tooltip.style("stroke", "green");
        tooltip.text(data[0].genre);
      };

      const mouseOutMethod = (data) => {
        let genreNumber = genreLineMapping[data[0].genre].toString();
        lines
          .selectAll("path")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("stroke", (d) => colourMapping[d.genre])
          .attr("stroke-width", this.strokeWidth);
        lines
          .selectAll("circle")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("fill", (d) => colourMapping[d.genre])
          .attr("r", this.radius);
        d3.selectAll("#genre" + genreNumber)
          .selectAll("text")
          .transition()
          .duration(150)
			.ease(d3.easeCubicOut)
          .attr("dy", this.radius / 2)
          .style("font-size", "14px");
        return tooltip.style("visibility", "hidden");
      };
    },

    redraw() {
      // Clear the bump chart
      d3.select("#bump-chart").select("svg").remove();

      // And redraw it
      this.draw();
    },

    handleClick() {
      if (this.beginYear && this.endYear) {
        this.redraw();
      }
    },

    init() {
      this.songData = this.$dataGetter.dataRegular;
      this.genreData = this.$dataGetter.dataWGenre;
      this.subgenreData = this.$dataGetter.subgenres;
      this.colourData = this.$dataGetter.legendGenreColours;

      this.tooltip = d3.select("#tooltip").attr("class", "tooltip").text("");

      this.createYearSelect();
      this.createSubgenreMapping();
      this.createArtistGenreMapping();

      this.draw();
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
#bump-chart {
  width: 130%;
  overflow-x: auto;
  transform: translateX(-100px);
}
.yearSelector {
  width: fit-content;
  margin-right: 10px;
}
.y .tick line {
  visibility: hidden;
}
.button {
  color: white;
  background-color: rgb(30, 215, 96);
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
}
.button:hover {
  background-color: rgb(26, 177, 79);
}
</style>
