<template>
  <div id="bar-chart" />
</template>

<script>
import { fetchColour } from "../js/dataManipulation.js";
import * as d3 from "d3";

export default {
  name: "BarChart",
  props: {
    bardata: {
      required: true,
    },
    barcolor: {
      default: "#aaaaaa",
      required: false,
    },
    horizontal: {
      default: true,
      required: false,
    },
    width: {
      default: 750,
      required: false,
    },
    height: {
      default: 400,
      required: false,
    },
    xname: {
      default: "",
      required: false,
    },
    name: {
      default: "",
      required: false,
    },
  },
  components: {},
  data() {
    return {
      margin: {
        top: 50,
        right: 54,
        left: 200,
        bottom: 50,
      },
    };
  },
  computed: {
    reversedData: function () {
      return this.bardata.slice().reverse();
    },
  },
  methods: {
    init() {
      d3.select("#bar-chart").selectAll("*").remove();
      const svg = d3
        .select("#bar-chart")
        .append("svg")
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);
      const g = svg
        .append("g")
        .style(
          "transform",
          `translate(${this.margin.left}px, ${this.margin.top}px)`
        );

      const genreGetter = (d) => d.genre;
      const amountGetter = (d) => d.amount;
      const dmax = d3.max(this.reversedData, amountGetter);
      const onHoverZoomFactor = 1.2;

      let gridlineMax = Math.max(...this.bardata.map((d) => d.amount)) / 10.0;
      if (this.horizontal) {
        const yScale = d3
          .scaleBand()
          .domain(this.reversedData.map(genreGetter))
          .range([this.height, 0])
          .padding(0.2);
        const xScale = d3
          .scaleLinear()
          .domain([0, dmax])
          .range([0, this.width]);

        g.append("g")
          .attr("class", "axis")
          .attr("transform", `translate(0, ${this.height})`)
          .call(d3.axisBottom(xScale));

        if (this.xname !== "") {
          g.append("text")
            .attr("text-anchor", "center")
            .attr("x", this.width / 2 - 30)
            .attr("y", this.height + 40)
            .text(this.xname);
        }

        g.append("g").attr("class", "axis").call(d3.axisLeft(yScale));

        g.append("text").attr("font-weight", 600).attr("y", -5).text(this.name);

        for (let i = 0; i < gridlineMax; i += 2) {
          let thickness = 1;
          let x = (this.width / gridlineMax) * i + thickness / 2.0;
          g.append("line")
            .attr("class", "gridline")
            .attr("x1", x)
            .attr("x2", x)
            .attr("y1", 0)
            .attr("y2", this.height)
            .attr("stroke-width", thickness);
        }

        let horBars = g.selectAll(".bar").data(this.reversedData).enter();

        horBars
          .append("text")
          .attr("class", "label")
          .attr("opacity", 0)
          .attr("id", function (d) {
            return d.genre.replace(/[^A-Za-z0-9]/g, "");
          })
          .attr("text-anchor", "left")
          .attr("x", function (d) {
            return xScale(d.amount);
          })
          .attr("y", function (d) {
            return yScale(d.genre) + yScale.bandwidth() / 2 + 4;
          })
          .text(function (d) {
            return d.amount;
          });

        horBars
          .append("rect")
          .style("fill", (d) =>
            this.barcolor === "#aaaaaa"
              ? fetchColour(
                  this.$dataGetter.subgenres,
                  this.$dataGetter.legendGenreColours,
                  d.genre
                )
              : this.barcolor
          )
          .attr("class", "bar")
          .attr("x", 1)
          .attr("y", function (d) {
            return yScale(d.genre);
          })
          .attr("width", function (d) {
            return xScale(d.amount);
          })
          .attr("height", yScale.bandwidth())
          .on("mouseover", function (d, i) {
            d3.select("#" + i.genre.replace(/[^A-Za-z0-9]/g, ""))
              .transition()
              .duration(750)
              .ease(d3.easeElasticOut)
              .attr("x", function (d) {
                return xScale(d.amount) + 10;
              })
              .attr("opacity", 1);
            d3.select(this)
              .transition()
              .duration(250)
              .ease(d3.easeExpOut)
              .attr(
                "y",
                yScale(i.genre) -
                  ((onHoverZoomFactor - 1) / 2) * yScale.bandwidth()
              )
              .attr("height", onHoverZoomFactor * yScale.bandwidth());
          })
          .on("mouseout", function (d, i) {
            d3.select("#" + i.genre.replace(/[^A-Za-z0-9]/g, ""))
              .transition()
              .duration(1000)
              .ease(d3.easeExpOut)
              .attr("x", function (d) {
                return xScale(d.amount);
              })
              .attr("opacity", 0);
            d3.select(this)
              .transition()
              .duration(500)
              .ease(d3.easeExpOut)
              .attr("y", yScale(i.genre))
              .attr("height", yScale.bandwidth());
          });
      } else {
        const yScale = d3
          .scaleLinear()
          .domain([0, dmax])
          .range([this.height, 0]);
        const xScale = d3
          .scaleBand()
          .domain(this.bardata.map(genreGetter))
          .range([0, this.width])
          .padding(0.2);

        g.append("g")
          .attr("class", "axis")
          .attr("transform", `translate(0, ${this.height})`)
          .call(d3.axisBottom(xScale));

        if (this.xname !== "") {
          g.append("text")
            .attr("text-anchor", "center")
            .attr("x", this.width / 2 - 30)
            .attr("y", this.height + 40)
            .text(this.xname);
        }

        g.append("g").attr("class", "axis").call(d3.axisLeft(yScale));

        for (let i = 0; i < gridlineMax; i += 2) {
          let thickness = 1;
          let y = (this.height / gridlineMax) * i + thickness / 2.0;
          g.append("line")
            .attr("class", "gridline")
            .attr("x1", 0)
            .attr("x2", this.width)
            .attr("y1", y)
            .attr("y2", y)
            .attr("stroke-width", thickness);
        }

        let vertBars = g.selectAll(".bar").data(this.bardata).enter();

        vertBars
          .append("text")
          .attr("class", "label")
          .attr("opacity", 0)
          .attr("id", function (d) {
            return d.genre.replace(/[^A-Za-z0-9]/g, "");
          })
          .attr("text-anchor", "middle")
          .attr("x", function (d) {
            return xScale(d.genre) + xScale.bandwidth() / 2;
          })
          .attr("y", function (d) {
            return yScale(d.amount);
          })
          .text(function (d) {
            return d.amount;
          });

        vertBars
          .append("rect")
          .style("fill", (d) =>
            fetchColour(
              this.$dataGetter.subgenres,
              this.$dataGetter.legendGenreColours,
              d.genre
            )
          )
          .attr("class", "bar")
          .attr("x", function (d) {
            return xScale(d.genre);
          })
          .attr("y", function (d) {
            return yScale(d.amount);
          })
          .attr("width", xScale.bandwidth())
          .attr("height", function (d) {
            return yScale(dmax - d.amount);
          })
          .on("mouseover", function (d, i) {
            d3.select("#" + i.genre.replace(/[^A-Za-z0-9]/g, ""))
              .transition()
              .duration(750)
              .ease(d3.easeElasticOut)
              .attr("y", function (d) {
                return yScale(d.amount) - 10;
              })
              .attr("opacity", 1);
            d3.select(this)
              .transition()
              .duration(250)
              .ease(d3.easeExpOut)
              .attr(
                "x",
                xScale(i.genre) -
                  ((onHoverZoomFactor - 1) / 2) * xScale.bandwidth()
              )
              .attr("width", onHoverZoomFactor * xScale.bandwidth());
          })
          .on("mouseout", function (d, i) {
            d3.select("#" + i.genre.replace(/[^A-Za-z0-9]/g, ""))
              .transition()
              .duration(1000)
              .ease(d3.easeExpOut)
              .attr("y", function (d) {
                return yScale(d.amount);
              })
              .attr("opacity", 0);
            d3.select(this)
              .transition()
              .duration(500)
              .ease(d3.easeExpOut)
              .attr("x", xScale(i.genre))
              .attr("width", xScale.bandwidth());
          });
      }
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    bardata(newdata, olddata) {
      this.init();
    },
  },
};
</script>
<style>
.bar {
  transition: 100ms filter ease-out;
}
.bar:hover {
  filter: brightness(90%);
}
.axis {
  font: 15px Montserrat, sans-serif;
}
.label {
  font: 15px Montserrat, sans-serif;
}
</style>
