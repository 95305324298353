<template>
  <div class="container">
    <div
      v-for="(colour, item) in legend_data"
      :key="item"
      class="item-container"
    >
      <div :style="{ 'background-color': colour }" class="circle" />
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Legend",
  props: ["legend_data"],
};
</script>
<style>
.container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  left: 0;
  background-color: rgba(30, 215, 96, 0.05);
  border-radius: 0 30px 30px 0;
  padding: 10px 10px 10px 20px;
  border-left: solid 5px rgb(30, 215, 96);
}
.item-container {
  display: flex;
  align-items: center;
  padding: 5px 0 5px 0;
}
.circle {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
</style>
