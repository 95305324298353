<template>
  <div>
    <div class="content">
      <div class="vis-container">
        <h2 class="vis-title">{{ bumpChartData.title }}</h2>
        <p class="vis-text">{{ bumpChartData.text }}</p>
        <BumpChart />
      </div>
      <div class="vis-container">
        <h2 class="vis-title">{{ forceGraphData.title }}</h2>
        <p class="vis-text">{{ forceGraphData.text }}</p>
        <ForceGraph :artistData="false" @genreClicked="fillBarData" />
        <BarChart
          v-if="showBarChart"
          :bardata="barChartData"
          :name="barName"
          :barcolor="barColor"
          xname="Popularity"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import { getColumn } from '../js/dataManipulation.js'
import BarChart from "../components/BarChart.vue";
import BumpChart from "../components/BumpChart.vue";
import ForceGraph from "../components/ForceGraph.vue";
import { getTopArtists, fetchColour } from "../js/dataManipulation.js";

export default {
  name: "Page2",
  components: {
    BumpChart,
    ForceGraph,
    BarChart,
  },
  data() {
    return {
      showBarChart: false,
      barChartData: undefined,
      barColor: undefined,
      barName: undefined,
      bumpChartData: {
        title: "Popularity of genres with artists over time",
        text: "This Bump Chart shows the evolutions of the popularity of genres with artists during the selected time period. This data is collected by looking at the genres of all songs that where released in a given years and ranking these based on the amount of corresponding songs. In this example we can see that Pop, Rock and Hip Hop have mostly been the most popular genres. In 2008 we can see a spike in older music such as Folk, Rock and Classical music.",
      },
      forceGraphData: {
        title: "How often do genres occur together?",
        text:
          "This graph shows the genres and sub-genres, as well as how often they occur together. Two genres are connected when there are 100 songs that use both genres. This graph shows how some genres are highly inter-connected, such as Pop and Rock, while others like Swing and Big Band form a single pair.",
      },
    };
  },
  mounted() {},
  methods: {
    fillBarData(genre) {
      this.barChartData = getTopArtists(this.$dataGetter.dataWGenre, genre, 5);
      this.barColor = fetchColour(
        this.$dataGetter.subgenres,
        this.$dataGetter.legendGenreColours,
        genre
      );
      let genreSplits = genre.split(" ");
      for (let i = 0; i < genreSplits.length; i++) {
        genreSplits[i] =
          genreSplits[i][0].toUpperCase() + genreSplits[i].substr(1);
      }
      this.barName = genreSplits.join(" ");
      this.showBarChart = true;
    },
  },
};
</script>
