export function getColumn(data, column) {
  if (Object.keys(data[0]).includes(column)) {
    return data.map((x) => x[column]);
  } else {
    return -1;
  }
}

export function fetchColour(subgenres, mainGenreColours, genre) {
  for (var i = 0; i < subgenres.length; i++) {
    if (subgenres[i].subgenre === genre) {
      return mainGenreColours[subgenres[i].genre];
    }
  }
  // 'other' colour
  return "#aaaaaa";
}

export function getTopArtists(data, genre, n) {
  let artist_popularities = [];
  for (const i of data) {
    if (typeof i["genres"] === "undefined" || i["genres"][0] === '"[]"')
      continue;
    if (i["genres"].includes(genre)) {
      artist_popularities.push(i);
    }
  }
  artist_popularities.sort((first, second) =>
    second.popularity > first.popularity ? 1 : -1
  );
  return artist_popularities
    .slice(0, n)
    .map((artist) => ({
      genre: artist.artists,
      amount: artist.popularity.toFixed(2),
    }));
}
