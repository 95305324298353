<template>
  <div>
    <input type="range" min=0 max=100 class="slider" v-model="sliderValue" @mouseup="emitNewVal">
  </div>
</template>

<script>
export default {
  name: "Slider",
  components: {},
  data() {
    return {
      sliderValue: 0,
    };
  },
  props: {
      min: {
          type: Number,
          required: true
      },
      max: {
          type: Number,
          required: true
      },
      val: {
          type: Number,
          required: false,
          default: -1
      },
      name: {
          type: String,
          required: false,
          default: "no-name"
      }
  },
  watch: {
      val(newVal, oldVal) {
          this.sliderValue = this.val / (this.max - this.min) * 100;
      }
  },
  mounted() {
      if (this.val > 0) {
          this.sliderValue = this.val / (this.max - this.min) * 100;
      } else {
          this.sliderValue = 50;
      }
  },
  methods: {
      emitNewVal() {
          const outVal = this.sliderValue / 100 * (this.max - this.min);
          this.$emit('newVal', outVal, this.name);
      }
  }
}
</script>

<style>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--color-primary);
  cursor: pointer;
  border-radius: 100px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--color-primary);
  cursor: pointer;
}
</style>
