<template>
  <div id="header">
    <div id="header-top">
      <div id="logo-top">
        <router-link to="/">DATA</router-link>
      </div>
      <div id="nav-container">
        <div
          id="nav-item"
          class="nav-link"
          v-for="link in links"
          :key="link.name"
        >
          <router-link :to="link.href">{{ link.name }}</router-link>
        </div>
      </div>
    </div>
    <div id="header-bottom">
      <div id="logo-bottom">VISUALISATION</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      links: [
        {name: 'ARTISTS', href: '/page1'},
        {name: 'GENRES', href: '/page2'},
        {name: 'FEATURES', href: '/page3'},
        {name: 'SONGFINDER', href: '/songfinder'}
      ]
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  display: grid;
  grid-template-rows: 60% auto;
  height: var(--header-height);
}

#header-top,
#header-bottom {
  display: grid;
  grid-template-columns: 150px auto;
}

#header-top {
  background: var(--color-primary);
}

a {
  color: white;
  text-decoration: none;
}

.nav-link {
  position: relative;
}

.nav-link::after {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  background: whitesmoke;
  left: 0;
  bottom: -3px;
  border-radius: 5px;
  transform: scaleX(0) translateX(-10px);
  transform-origin: left;
  transition: transform 100ms ease-out;
}

.nav-link:hover::after {
  transform: scaleX(1) translateX(0);
}

#logo-top {
  font-size: 24pt;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#logo-bottom {
  font-size: 9pt;
  font-weight: bold;
  padding-top: 4px;
  padding-left: 6px;
}

#nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

#nav-item {
  color: white;
  padding: 0 10px 0 10px;
}
</style>
