import Papa from "papaparse";

export default class DataGetter {
  legendGenreColours = {
    Pop: "#ea2b1f",
    "R&B": "#edae49",
    Classical: "#f9df74",
    Country: "#07e0f0",
    Electronica: "#1b5299",
    Folk: "#9fc2cc",
    "Hip Hop": "#F49FBC",
    Jazz: "#bb5577",
    "New Age": "#79b473",
    Reggae: "#efca08",
    Rock: "#820b8a",
    Other: "#aaaaaa",
  };
  featureColours = {
    Acousticness: "#1f77b4",
    Danceability: "#ff7f0e",
    Duration_ms: "#2ca02c",
    Energy: "#d62728",
    Instrumentalness: "#9467bd",
    Liveness: "#8c564b",
    Loudness: "#e377c2",
    Speechiness: "#7f7f7f",
    Tempo: "#bcbd22",
    Valence: "#17becf",
    Popularity: "black",
  };

  allDataLoaded = false;
  dataRegular = undefined;
  dataGenre = undefined;
  dataArtist = undefined;
  dataYear = undefined;
  dataWGenre = undefined;
  genreLinks = undefined;
  artistLinks = undefined;
  subgenres = undefined;
  correlation = undefined;
  colours = undefined;

  dataLoaded = {
    dataRegular: false,
    dataGenre: false,
    dataArtist: false,
    dataYear: false,
    dataWGenre: false,
    genreLinks: false,
    subgenres: false,
    artistLinks: false,
    correlation: false,
    colours: false,
  };
  dateNames = {
    dataRegular: "/data/data.csv",
    dataGenre: "/data/data_by_genres.csv",
    dataArtist: "/data/data_by_artist.csv",
    dataYear: "/data/data_by_year.csv",
    dataWGenre: "/data/data_w_genres.csv",
    genreLinks: "/data/genre_links.csv",
    subgenres: "/data/subgenres.csv",
    artistLinks: "/data/artist_links.csv",
    correlation: "/data/data_correlation.csv",
    colours: "/data/main_genre_colours.csv",
  };

  parseList(value, column) {
    if (
      ["genres", "artists"].includes(column) &&
      ["[", '"'].includes(value[0])
    ) {
      var no_brackets = value
        .replaceAll("['", "")
        .replaceAll("']", "")
        .replaceAll('["', "")
        .replaceAll('"]', "");

      let regex = /', '|', "|", '|", "/;

      return no_brackets.split(regex);
    }
    return value;
  }

  fetchData(path, cb, errorCb) {
    fetch(path)
      .then((response) => response.text())
      .then((csv) => {
        Papa.parse(csv, {
          header: true,
          complete: cb,
          transform: this.parseList,
          dynamicTyping: true,
          error: errorCb,
        });
      });
  }

  loadAllData() {
    return this.loadDataSets([
      "dataRegular",
      "dataGenre",
      "dataArtist",
      "dataYear",
      "dataWGenre",
      "genreLinks",
      "subgenres",
      "correlation",
      "colours",
      "artistLinks",
    ]);
  }

  // Data loading function with caching, when this is called, all datasets in
  // the keys list are loaded
  loadDataSets(keys) {
    return new Promise((resolve, reject) => {
      Object.keys(this.dataLoaded).forEach((key) => {
        if (!keys.includes(key)) {
          this[key] = undefined;
          this.dataLoaded[key] = false;
        }
      });

      keys.forEach((key) => {
        if (!this.dataLoaded[key]) {
          this.fetchData(
            this.dateNames[key],
            (results) => {
              this[key] = results.data;
              this.dataLoaded[key] = true;
              if (keys.every((x) => this.dataLoaded[x])) {
                // this.dataLoaded = true;
                resolve("All datasets loaded");
              }
            },
            () => reject(`Something went wrong when loading ${key}`)
          );
        }
      });
    });
  }
}
