import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Page1 from '../views/Page1.vue';
import Page2 from '../views/Page2.vue';
import Page3 from '../views/Page3.vue';
import SongFinderPage from '../views/SongFinderPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/page1",
    name: "Page 1",
    component: Page1,
  },
  {
    path: "/page2",
    name: "Page 2",
    component: Page2,
  },
  {
    path: '/page3',
    name: 'Page 3',
    component: Page3
  },
  {
    path: '/songfinder',
    name: 'Songfinder',
    component: SongFinderPage
  }
]

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
