<template>
  <div>
    <div class="content">
      <div id="select-header">Choose one or more artists</div>
      <div class="select-container">
        <Multiselect
          v-model="chosenArtists"
          :options="artists"
          :searchable="true"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
        ></Multiselect>
      </div>
      <div class="vis-container">
        <h2 class="vis-title">{{ releaseChartData.title }}</h2>
        <p class="vis-text">{{ releaseChartData.text }}</p>
        <ReleasesGraph :chosenArtists="chosenArtists" />
      </div>
      <div class="vis-container">
        <h2 class="vis-title">{{ forceGraphData.title }}</h2>
        <p class="vis-text">{{ forceGraphData.text }}</p>
        <ForceGraph
          :artist_data="true"
          :minLinkStrength="2"
          :chosenArtists="chosenArtists"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getColumn } from '../js/dataManipulation.js';
import ReleasesGraph from '../components/Releases.vue';
import ForceGraph from '../components/ForceGraph.vue';
import Multiselect from "vue-multiselect";

export default {
  name: "Page1",
  components: {
    ReleasesGraph,
    Multiselect,
    ForceGraph
  },
  data() {
    return {
      chosenArtists: ["Kanye West", "David Bowie"],
      artists: [],
      releaseChartData: {
        title: "Releases timeline",
        text: "This timeline displays how often the selected artist(s) release music. The timeline is scaled so it starts at the earliest release and stops at the current date. The amount of songs released on a certain day can be derived from the height of the bar on that day. In the example, we can see that Kanye West only started releasing songs arond 2002 while David Bowie startd as early as 1967. We can also see that David Bowie released a lot of albums, as indicated by the longer lines. In 2015, he also re-released most of his songs as a re-master, causing the spike we see there. We can also see that Kanye West released fewer albums and more singles since 2016.",
      },
      forceGraphData: {
        title: "Who did these artists collaborate with?",
        text:
          "This graph represents with whom the currently selected artist has worked together with. Every dot represents an artist. The selected artist will have a connection with an artist with whom they have more than 2 collaborations. Then the connected artist will in turn have connections with their collaborators. In this example we can see that David Bowie does not have a lot of frequent collaborations, while Kanye West has frequently worked with a lot of other artists.",
      },
    };
  },
  mounted() {
    this.artists = [
      ...new Set(getColumn(this.$dataGetter.dataArtist, "artists").flat()),
    ];
    this.artists.sort();
  },
};
</script>

<style>
#select-header {
  margin-bottom: 15px;
}

.vis-container {
  margin: 50px 0 50px 0;
}

.vis-title,
.vis-text {
  margin-bottom: 20px;
}

.vis-title {
  font-weight: bolder;
}

.vis-text {
  text-align: left;
}
</style>
