<template>
  <div id="force-tooltip-container">
    <div id="tt-arrow" />
    <div id="tt-info" v-if="!hasError">
      <div id="tt-info-name">{{ title }}</div>
      <div id="tt-info-desc">{{ subtitle }}</div>
      <div id="tt-info-detail-container" v-if="description !== ''">
        <div id="tt-info-detail">{{ description }}</div>
      </div>
    </div>
    <div id="tt-error" v-else>
      <div id="tt-info-name">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForceToolTip",
  data() {
    return {
      title: "",
      subtitle: "",
      description: "",
      hasError: false,
    };
  },
  props: {
    artistData: {
      type: Boolean,
      required: false,
      default: true,
    },
    hovered: {
      type: String,
      required: false,
      default: "Kanye West",
    },
  },
  methods: {
    findArtistInfo(artist) {
      for (const entry of this.$dataGetter.dataArtist) {
        if (entry.artists == artist) {
          return entry;
        }
      }
    },
  },
  watch: {
    hovered(newNode, oldNode) {
      //   about,description,url,error
      if (this.artistData) {
        this.title = newNode;
        const info = this.findArtistInfo(newNode);
        if (info && info.error == null) {
          this.hasError = false;
          this.subtitle = info.about;
          this.description = info.description;
        } else {
          this.hasError = true;
          this.subtitle = "";
          this.description = "";
        }
      } else {
        this.title = newNode
          .split(" ")
          .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1))
          .join(" ");
        let subtitleInfo = "Other";
        for (const sg of this.$dataGetter.subgenres) {
          if (sg.subgenre === newNode) {
            subtitleInfo = sg.genre;
            break;
          }
        }
        this.subtitle = `Subgenre of ${subtitleInfo}`;
      }
    },
  },
};
</script>
<style>
#force-tooltip-container {
  transition: width 2s ease-in-out;
  width: max-content;
  min-height: max-content;
  border: var(--color-primary) solid 1px;
  border-radius: 20px;
  background: white;
  padding: 10px;
  text-align: left;
}

#tt-info-name {
  font-size: 20px;
  font-weight: bolder;
}

#tt-info-desc {
  font-size: 15px;
  font-weight: bold;
}

#tt-info-detail {
  margin-top: 10px;
  font-size: 14px;
  font-weight: lighter;
  font-style: italic;
  max-width: 350px;
}
</style>
