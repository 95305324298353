<template>
  <div>
    <table>
      <tbody>
        <tr>
          <td>Start:</td>
          <td>
            <Multiselect
              v-model="chosenBegin"
              :options="yearOptions"
              :close-on-select="false"
              :clear-on-select="false"
              @input="redraw"
              @select="redraw"
              @close="redraw"
              class="yearSelector"
            ></Multiselect>
          </td>
          <td>End:</td>
          <td>
            <Multiselect
              v-model="chosenEnd"
              :options="yearOptions"
              :close-on-select="false"
              :clear-on-select="false"
              @input="redraw"
              @select="redraw"
              @close="redraw"
              class="yearSelector"
            ></Multiselect>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="line-chart" />
    <div id="tooltip" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import * as d3 from "d3";
import * as d3Collection from "d3-collection";
const FEATURES = [
  "Acousticness",
  "Danceability",
  "Duration_ms",
  "Energy",
  "Instrumentalness",
  "Liveness",
  "Loudness",
  "Speechiness",
  "Tempo",
  "Valence",
  "Popularity",
];

function scaleValue(value, min, max) {
  return (value - min) / (max - min);
}
function fixData(data, dmax, features, features_rescale) {
  let retval = [];
  for (let line of data) {
    let year = line.year;
    if (year) {
      for (let feature of features) {
        let amount = line[feature.toLowerCase()];
        // Rescale the amount
        let featureScale = features_rescale[feature];
        amount = dmax * scaleValue(amount, ...featureScale);

        let newVal = {
          year: year,
          feature: feature,
          amount: amount,
        };
        retval.push(newVal);
      }
    }
  }
  return retval;
}

export default {
  name: "LineChart",
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    chosenFeatures: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    Multiselect,
  },
  watch: {
    chosenFeatures() {
      this.redraw();
    },
  },
  data() {
    return {
      // The thickness of the lines, first for when another line is hovered over, second for default, last for when the line is hovered over
      lineThickness: [1, 3, 5],
      // The opacity of the lines, the first for when another line is hovered over, the second for default
      lineOpacity: [0.5, 1],
      chosenBegin: 0,
      chosenEnd: 0,
      margin: {
        top: 50,
        right: 50,
        left: 50,
        bottom: 50,
      },
      yearOptions: [],
      options: [],
      dmax: 100,
      data: [
        { year: 2018, feature: "danceability", amount: 60.27309137803987 },
        { year: 2019, feature: "danceability", amount: 60.31014169171321 },
        { year: 2020, feature: "danceability", amount: 60.52853749417798 },
        { year: 2021, feature: "danceability", amount: 65.24883695652172 },
        { year: 2018, feature: "acousticness", amount: 23.383613501842265 },
        { year: 2019, feature: "acousticness", amount: 26.134430419063964 },
        { year: 2020, feature: "acousticness", amount: 20.230315157196035 },
        { year: 2021, feature: "acousticness", amount: 34.02533782663046 },
      ],
    };
  },
  methods: {
    draw(data) {
      const svg = d3
        .select("#line-chart")
        .append("svg")
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom);
      const g = svg
        .append("g")
        .style(
          "transform",
          `translate(${this.margin.left}px, ${this.margin.top}px)`
        );

      const amountGetter = (d) => d.amount;
      const parseTime = d3.timeParse("%Y");
      const yearGetter = (d) => parseTime(d.year);
      const years = data.map(yearGetter);
      const domain = [Math.min(...years), Math.max(...years)];
      const xScale = d3.scaleTime().domain(domain).rangeRound([0, this.width]);

      const yScale = d3
        .scaleLinear()
        .domain([0, this.dmax])
        .rangeRound([this.height, 0]);
      g.append("g")
        .attr("class", "axis")
        .attr("transform", `translate(0, ${this.height})`)
        .call(
          d3
            .axisBottom(xScale)
            .ticks(Math.min(7, this.chosenEnd - this.chosenBegin))
        );
      g.append("g").attr("class", "axis").call(d3.axisLeft(yScale));
      g.append("g")
        .attr("class", "axis")
        .attr("transform", `translate(${this.width}, 0)`)
        .call(d3.axisRight(yScale));
      for (let i = 0; i < 10; i++) {
        let thickness = 1;
        let y = (this.height / 10) * i + thickness / 2.0;
        g.append("line")
          .attr("class", "gridline")
          .attr("x1", 0)
          .attr("x2", this.width)
          .attr("y1", y)
          .attr("y2", y)
          .attr("stroke-width", thickness);
      }

      let sumstat = d3Collection
        .nest()
        .key((d) => d.feature)
        .entries(data);

      let tooltip = this.tooltip;
      let features = this.chosenFeatures;
      let lineThickness = this.lineThickness;
      let lineOpacity = this.lineOpacity;
      let color = (d) => this.$dataGetter.featureColours[d];
      let dmax = this.dmax;
      let height = this.height;
      let margin = this.margin;
      g.selectAll(".line")
        .attr("class", "line")
        .data(sumstat)
        .enter()
        .append("path")
        .attr("d", function (d) {
          return d3
            .line()
            .x((d) => xScale(yearGetter(d)))
            .y((d) => yScale(amountGetter(d)))
            .curve(d3.curveCardinal)(d.values);
        })
        .attr("id", (d) => d.key)
        .attr("fill", "none")
        .attr("stroke", (d) => color(d.key))
        .attr("stroke-width", lineThickness[1])
        .attr("stroke-opacity", lineOpacity[1])
        .attr("pointer-events", "visibleStroke")
        .on("mouseover", function (d, i) {
          for (let f of features) {
            let s = d3.select("#" + f);
            s.style("stroke-width", lineThickness[0]).style(
              "stroke-opacity",
              lineOpacity[0]
            );
          }
          d3.select(this).style("stroke-width", lineThickness[2]);
          d3.select(this).style("stroke-opacity", lineOpacity[1]);
          tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (d, i) {
          let yVal = dmax * (((height - d.layerY + margin.top) * 1.0) / height);
          tooltip.text(`${i.key} - ${Math.round(yVal * 100) / 100.0}`);
          tooltip
            .style("top", d.pageY - 10 + "px")
            .style("left", d.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          for (let f of features) {
            let s = d3.select("#" + f);
            s.style("stroke-width", lineThickness[1]).style(
              "stroke-opacity",
              lineOpacity[1]
            );
          }
          return tooltip.style("visibility", "hidden");
        });
    },
    redraw() {
      let data = this.data.filter(
        (d) => this.chosenBegin <= d.year && d.year <= this.chosenEnd
      );
      data = fixData(
        data,
        this.dmax,
        this.chosenFeatures,
        this.features_rescale
      );

      // Clear the svg
      d3.select("#line-chart").select("svg").remove();

      // And redraw it
      this.draw(data);
    },
    init() {
      this.tooltip = d3.select("#tooltip").attr("class", "tooltip").text("");

      // Assuming data is between 0 and 1, rescale when loading data if necessary
      this.data = this.$dataGetter.dataYear;

      const years = this.data.map((d) => d.year).filter((d) => d != undefined);

      this.chosenBegin = Math.min(...years);
      this.chosenEnd = Math.max(...years);
      this.yearOptions = years;
      let features = this.chosenFeatures;

      let features_rescale = {};
      for (let feature of FEATURES) {
        let mappedValues = this.data
          .map((d) => d[feature.toLowerCase()])
          .filter((d) => d != undefined);
        let min = Math.min(...mappedValues);
        let max = Math.max(...mappedValues);
        if (max > 0) {
          features_rescale[feature] = [0, max];
        } else {
          features_rescale[feature] = [0, min];
        }
      }
      this.features_rescale = features_rescale;
      // Filter the years
      let data = this.data.filter(
        (d) => this.begin <= d.year && d.year <= this.end
      );
      data = fixData(this.data, this.dmax, features, this.features_rescale);
      this.draw(data);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.yearSelector {
  width: fit-content;
  margin-right: 10px;
}
</style>
