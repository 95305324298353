<template>
    <div id="songfinder">
        <Multiselect
          v-model="chosenSong"
          :options="allSongs"
          :searchable="true"
          :multiple="false"
          :close-on-select="false"
          :clear-on-select="false"
        ></Multiselect>
        <div id="song-info">
            <div id="sf-title">{{title}}</div>
            <div id="sf-artist">{{artist}}</div>
            <a :href="url" id="sf-link"><div>Listen on spotify</div></a>
        </div>
        <div id="slider-container">
            <div v-for="(feature, index) in Object.keys(features)" :key="index">
                <div id='slider-content'>
                    <div id='feature-name'>{{feature}}</div>
                    <Slider :min="features[feature].min" :max="features[feature].max" :name="feature" :val="features[feature].value" @newVal="updateVal" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '../components/Slider.vue';
import Multiselect from "vue-multiselect";
import { getColumn } from '../js/dataManipulation.js';

export default {
  name: "SongFinder",
  data() {
    return {
        chosenSong: "",
        title: "",
        artist: "",
        url: "",
        allSongs: [],
        features: {
            'danceability': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'acousticness': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'instrumentalness': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'liveness': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'loudness': {
                'min': -60,
                'max': 0,
                'value': -30
            },
            'speechiness': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'valence': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'energy': {
                'min': 0,
                'max': 1,
                'value': 0.5
            },
            'popularity': {
                'min': 0,
                'max': 100,
                'value': 50
            }
        }
    };
  },
  props: {},
  components: {
      Slider,
      Multiselect
  },
  methods: {
      updateVal(newVal, feature) {
        this.features[feature].value = newVal;
        const bestMatch = this.findSmallestDiff();
        this.title = bestMatch.name;
        this.artist = Array.isArray(bestMatch.artists) ? bestMatch.artists.join(', ') : bestMatch.artists.toString();
        this.url = `https://open.spotify.com/track/${bestMatch.id}`;
      },

      findSongInfo(songTitle, artists) {
          for (const song of this.$dataGetter.dataRegular) {
              if (song.name == songTitle && artists.every(artist => song.artists.includes(artist))) {
                  return song;
              }
          }
      },
      findSmallestDiff(){
          let bestScore = 99999;
          let best = undefined;
          for (const song of this.$dataGetter.dataRegular) {
              let score = 0;
              for (const ft of Object.keys(this.features)) {
                  score += Math.abs(this.features[ft].value - song[ft]) / (this.features[ft].max - this.features[ft].min);
              }
              if (score < bestScore) {
                  bestScore = score;
                  best = song;
              }
          }
          return best;
      }
  },
  mounted() {
    const artists = getColumn(this.$dataGetter.dataRegular, 'artists').map(artists => (typeof artists === 'undefined') ? "Artist undefined" : (Array.isArray(artists) ? artists.join(', ') : artists.toString()));
    const songs = getColumn(this.$dataGetter.dataRegular, 'name');
    let combinedSongs = [];
    for (const [index, song] of songs.entries()) {
        combinedSongs.push(`${song} ~ ${artists[index]}`);
    }
    this.allSongs = combinedSongs;
    this.chosenSong = 'Goin Baby ~ DaBaby';
  },
  watch: {
      chosenSong(newSong, oldSong) {
          const gesplit = newSong.split(' ~ ');
          this.title = gesplit[0];
          this.artist = gesplit[1];
          const artistList = gesplit[1].split(', ');
          const info = this.findSongInfo(gesplit[0], artistList);
          this.url = `https://open.spotify.com/track/${info.id}`;
          for (const ft of Object.keys(this.features)) {
              this.features[ft].value = info[ft];
          }
      }
  }
};
</script>
<style>

#songfinder, #song-info, #slider-container {
    display: flex;
    flex-direction: column;
}

#song-info {
    padding: 10px;
}

#slider-content {
    margin: 10px 0 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 20% auto;
}

#sf-title {
    font-size: 25px;
    font-weight: bolder;
    margin: 10px 0 10px 0;
}

#sf-artist {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

#feature-name {
    text-align: left;
}

#sf-link {
    font-size: 20px;
    text-decoration: none;
    color: var(--color-primary);
}

</style>
