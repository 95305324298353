<template>
  <div>
    <div class="vis-container">
        <h2 class="vis-title">{{songfinder.title}}</h2>
        <p class="vis-text">{{songfinder.text}}</p>
        <SongFinder />
    </div>
  </div>
</template>

<script>
import SongFinder from '../components/SongFinder.vue';

export default {
  name: "SongFinderPage",
  components: {
    SongFinder
  },
  data() {
    return {
        songfinder: {
            title: 'Songfinder',
            text: "This final page, the songfinder, is not only a visualisation to show what properties or features your favorite songs have, it's also meant as an interactive way of discovering new songs. Do you like a certain artist, but you're looking for something that's just a little more energetic? Well, look up your favorite song by that artist and turn up the energy slider, see what comes out, maybe you'll even find a new favorite artist. Have fun."
        }
    };
  },
  mounted() {}
};
</script>
<style>
.vis-container {
  margin: 50px 0 50px 0;
}

.vis-title, .vis-text {
  margin-bottom: 20px;
}

.vis-title {
  font-weight: bolder;
}

.vis-text {
  text-align: left;
}
</style>
