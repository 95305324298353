<template>
  <div>
    <div class="content">
      <h1>Datavisualisation project</h1>
      <h2>Who are we?</h2>
      <p>
        We are six students at
        <a href="https://www.ugent.be/en" class="homelink">Ghent University</a>
        tasked with creating a datavisualisation. We were free to choose our own
        dataset, framework, etc. and had about ten weeks to create this website
        from start to finish.
      </p>
      <h2>Our choice of dataset</h2>
      <div class="datasetcontent">
        <img
          src="../assets/Spotify_Icon_RGB_Green.png"
          alt="Spotify logo"
          class="spotifylogo"
        />
        <p>
          To find a dataset we scouted the internet in search of one that piqued
          our interests. Not only did we want a dataset we enjoyed working with,
          it also had to provide us with enough data to actually show meaningful
          visualisations. We ended up narrowing our search down to three
          datasets:
          <a href="https://docs.irail.be/" class="homelink">NMBS iRail data</a>,
          <a
            href="https://www.kaggle.com/gsutters/the-human-freedom-index?select=hfi_cc_2020.csv"
            class="homelink"
            >the Human Freedom Index</a
          >
          and
          <a
            href="https://www.kaggle.com/yamaerenay/spotify-dataset-19212020-160k-tracks?select=data_by_artist.csv"
            class="homelink"
            >a Spotify dataset</a
          >. <br />Eventually we have decided upon the
          <span class="spotify">Spotify</span> one.
        </p>
      </div>
      <h2>So... what's in this dataset?</h2>
      <p>
        The dataset contains information about artists and tracks on Spotify.
        Aside from their general information like name and id, the artists each
        have their number of followers, genres and popularity. The tracks
        however are a bit more in-depth, as you can not only see the name,
        release year, duration and artists, but also a large number of
        attributes associated with the tracks. These are the features of the
        song and include properties such as danceablility, instrumentalness and
        speechiness.
      </p>
      <h2>What technologies did we use?</h2>
      <p>
        We were entirely free to choose whichever frameworks and platforms we
        preferred. For the development of the website we used the framework
        <a href="https://vuejs.org/" class="homelink">Vue.js</a> and for the
        visualisations we used
        <a href="https://d3js.org/" class="homelink">D3.js</a>.
      </p>
      <div id="linebreak" />
      <div id="tech-container">
        <a href="https://vuejs.org/" class="techlink">
          <img src="../assets/logo.png" class="tech" />
        </a>
        <a href="https://d3js.org/" class="techlink">
          <img src="../assets/d3.svg" class="tech" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style>
#home {
  font-size: 120px;
  font-weight: bold;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  text-align: left;
}

.content > * {
  margin-bottom: 1rem;
}

.content > p,
.datasetcontent {
  margin-bottom: 3rem;
}

#vuejs::after {
  content: "";
  display: inline-block;
  background: url("../assets/logo.png");
  background-size: 15px;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

#d3::after {
  content: "";
  display: inline-block;
  background: url("../assets/d3.svg");
  background-size: 18px;
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.spotify {
  color: whitesmoke;
  font-weight: bold;
  background: var(--color-primary);
  padding: 1px 5px;
  border-radius: 3px;
  border: 2px solid var(--color-primary);
  transition: all 200ms ease;
}

.homelink {
  color: inherit;
  text-decoration: none;
  position: relative;
  transition: color 100ms ease-out;
  white-space: nowrap;
}

.homelink::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 80%;
  left: 0;
  bottom: 0;
  transition: transform 150ms ease-out;
  transform-origin: left;
  background: var(--color-primary);
}

.homelink:hover {
  color: var(--color-primary);
}

.homelink:hover::after {
  transform: scaleX(1.1);
}

.datasetcontent {
  display: flex;
}

.spotifylogo {
  width: 200px;
  height: 200px;
  margin: auto;
  margin-right: 40px;
  display: block;
}

#tech-container {
  display: flex;
  margin: auto;
  width: max-content;
  margin-bottom: 50px;
}

.tech {
  width: 80px;
  height: 80px;
}

.techlink {
  width: 80px;
  height: 80px;
  margin: 0 20px;
}

#linebreak {
  height: 2px;
  width: 100px;
  margin: auto;
  margin-bottom: 30px;
  background: lightgrey;
}
</style>
