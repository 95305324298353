<template>
  <div id="fg-container" :style="getContainerStyle">
    <div id="force-graph" />
    <div id="force-tooltip">
      <ForceToolTip :hovered="hovered" :artistData="artistData" />
    </div>
  </div>
</template>

<script>
import ForceToolTip from "../components/ForceToolTip.vue";
import * as d3 from "d3";
import { fetchColour } from "../js/dataManipulation";

export default {
  name: "ForceGraph",
  components: {
    ForceToolTip,
  },
  data() {
    return {
      width: 850,
      height: 500,
      links: undefined,
      nodes: undefined,
      nodeRadius: 5,
      colorMap: undefined,
      hovered: this.artistData ? "Kanye West" : "k-pop",
      genreInfo: undefined,
    };
  },
  props: {
    chosenArtists: {
      type: Array,
      required: false,
    },
    artistData: {
      type: Boolean,
      required: false,
      default: true,
    },
    minLinkStrength: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  methods: {
    initArtistData() {
      const shades = ["#1db954", "#9a00ff", "#5960A6"];
      let colorMap = {};
      for (const artist of this.chosenArtists) {
        colorMap[artist] = shades[0];
      }
      this.clearGraph();
      let usedData = this.$dataGetter.artistLinks.filter(
        (x) =>
          (this.chosenArtists.includes(x.artist1) ||
            this.chosenArtists.includes(x.artist2)) &&
          x.strength >= this.minLinkStrength
      );
      let nodes = [
        ...new Set(usedData.map((node) => [node.artist1, node.artist2]).flat()),
      ];
      for (const artist of nodes) {
        if (!Object.keys(colorMap).includes(artist)) {
          colorMap[artist] = shades[1];
        }
      }
      usedData = this.$dataGetter.artistLinks.filter(
        (x) =>
          (nodes.includes(x.artist1) || nodes.includes(x.artist2)) &&
          x.strength >= this.minLinkStrength
      );
      nodes = [
        ...new Set(usedData.map((node) => [node.artist1, node.artist2]).flat()),
      ];
      for (const artist of nodes) {
        if (!Object.keys(colorMap).includes(artist)) {
          colorMap[artist] = shades[2];
        }
      }
      this.colorMap = colorMap;
      this.nodes = nodes.map((x) => ({ id: x })).map((d) => Object.create(d));
      this.links = usedData
        .map((node) => ({
          source: node.artist1,
          target: node.artist2,
          strength: node.strength,
        }))
        .map((d) => Object.create(d));
    },

    getArtistGoogle(artist) {
      return `https://www.google.com/search?q=${artist
        .toLowerCase()
        .split(" ")
        .join("+")}`;
    },

    init() {
      const simulation = d3
        .forceSimulation(this.nodes)
        .force(
          "link",
          d3.forceLink(this.links).id((d) => d.id)
        )
        .force("charge", d3.forceManyBody().strength(-3))
        .force("center", d3.forceCenter(this.width / 2, this.height / 2));

      const svg = d3
        .select("#force-graph")
        .append("svg")
        .attr("viewBox", [0, 0, this.width, this.height]);

      const link = svg
        .append("g")
        .attr("stroke", "#999")
        .attr("stroke-opacity", 0.6)
        .selectAll("line")
        .data(this.links)
        .join("line")
        .attr("stroke-width", 2);

      let ik = this;
      let tooltip = d3.select("#force-tooltip");
      const node = svg
        .append("g")
        .attr("stroke", "#fff")
        .attr("stroke-width", 1.5)
        .selectAll("circle")
        .data(this.nodes)
        .join("circle")
        .attr("r", this.nodeRadius)
        .style("fill", (d) =>
          this.artistData
            ? this.getArtistColor(d.id)
            : fetchColour(
                this.$dataGetter.subgenres,
                this.$dataGetter.legendGenreColours,
                d.id
              )
        )
        .call(this.drag(tooltip, simulation))
        .on("mouseover", function (d, i) {
          if (!ik.artistData) {
            d3.select(this).style("cursor", "pointer");
          }
          tooltip.style("visibility", "visible");
          ik.hovered = i.id;
        })
        .on("mousemove", (d) => {
          tooltip
            .style("top", d.pageY + "px")
            .style("left", d.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          if (!ik.artistData) {
            d3.select(this).style("cursor", "default");
          }
          tooltip.style("visibility", "hidden");
        })
        .on("click", (d, i) => {
          this.$emit("genreClicked", i.id);
        });

      simulation.on("tick", () => {
        link
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);

        node
          .attr(
            "cx",
            (d) =>
              (d.x = Math.max(
                this.nodeRadius,
                Math.min(this.width - this.nodeRadius, d.x)
              ))
          )
          .attr(
            "cy",
            (d) =>
              (d.y = Math.max(
                this.nodeRadius,
                Math.min(this.height - this.nodeRadius, d.y)
              ))
          );
      });
    },

    clearGraph() {
      d3.select("#force-graph").selectAll("*").remove();
    },

    getArtistColor(artist) {
      return this.colorMap[artist];
    },

    drag(tooltip, simulation) {
      function dragstarted(event) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.x;
        event.subject.fy = event.y;
        // tooltip.style("visibility", "hidden");
      }

      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
        // tooltip.style("visibility", "visible");
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    },
  },
  computed: {
    getContainerStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  mounted() {
    let l;
    if (this.artistData) {
      this.initArtistData();
    } else {
      l = this.$dataGetter.genreLinks.filter(
        (link) => link.value >= this.minLinkStrength
      );
      this.links = l.map((d) => Object.create(d));
      this.nodes = [
        ...new Set(l.map((node) => [node.source, node.target]).flat()),
      ]
        .map((x) => ({ id: x }))
        .map((d) => Object.create(d));
    }
    this.init();
  },
  watch: {
    chosenArtists() {
      if (this.artistData) {
        this.initArtistData();
        this.init();
      }
    },
  },
};
</script>
<style>
#force-tooltip {
  visibility: hidden;
  position: absolute;
}
</style>
